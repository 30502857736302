










import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'tool-tip',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['helpText']),
    tooltip(): string {
      return this.helpText(this.id) || ''
    },
  },
})
