












import Vue from 'vue'
import { mapGetters } from 'vuex'
import ToolTip from './ToolTip.vue'
import { slugify } from '../lib/formatters'
import { Answer, AnswerValue } from '@typesCustom/index'

export default Vue.extend({
  components: {
    ToolTip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    default: {
      type: [String, Number, Boolean],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['answers', 'displayText', 'optionText', 'descText', 'helpText']),
    name(): string {
      return slugify(this.displayText(this.id))
    },
  },
  data() {
    return {
      value: this.default as AnswerValue | undefined,
    }
  },
  watch: {
    value(newVal: AnswerValue, oldVal: AnswerValue) {
      if (newVal !== oldVal) {
        this.$emit('change', { id: this.id, value: this.value })
      }
    },
  },
  created() {
    const answer: Answer = this.answers.find((x: Answer) => x.id === this.id)
    const value: AnswerValue = answer ? answer.value : this.default
    this.value = value
    if (value) {
      this.$store.dispatch('saveAnswer', { id: this.id, value })
    }
  },
  mounted() {
    if (this.required) {
      this.$store.dispatch('require', this.id)
      if (this.value === null) {
        this.$store.dispatch('saveAnswer', { id: this.id, value: null })
      }
    }
  },
  destroyed() {
    if (this.required) {
      this.$store.dispatch('unRequire', this.id)
    }
  },
})
